export const COMMUNITY_USER_ACTION_BUTTON_ADDNOTE_FRAGMENT = `
  fragment communityUserActionButtonAddnoteFragment on CommunityUser {
    __typename
    id
    uid
    name
    firstName
    lastName
    pictureFileResource {
      uid
      schemaCode
      path
    }
  }
`;
